import React from "react";
import "./Faq.css";
import ControlledExpansionPanels from "../ControlledExpansionPanels";
const Faq = props => {
  return (
    <div className="container">
      <ControlledExpansionPanels {...props} />
    </div>
  );
};

Faq.defaultProps = { question: "This is Question", answer: "This is Answer" };

export default Faq;
