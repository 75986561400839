import React, { Component } from "react";
import Styles from "./LineItem.module.css";

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1;
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1;
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {
    const {
      LineItem,
      lineImg,
      content,
      contentRow,
      quantityContainer,
      quantity,
      quantityUpdate,
      variantTitle,
      title,
      price,
      remove,
      productImage
    } = Styles;
    return (
      <li className={LineItem}>
        <div className={lineImg}>
          {this.props.line_item.variant.image ? (
            <div
              style={{
                backgroundImage: `url(
                  '${this.props.line_item.variant.image.src}'
                )`
              }}
              alt={`${this.props.line_item.title} product shot`}
              className={productImage}
            />
          ) : null}
        </div>
        <div className={content}>
          <div className={contentRow}>
            <div className={variantTitle}>
              {this.props.line_item.variant.title}
            </div>
            <span className={title}>{this.props.line_item.title}</span>
          </div>
          <div className={contentRow}>
            <div className={quantityContainer}>
              <button
                className={quantityUpdate}
                onClick={() => this.decrementQuantity(this.props.line_item.id)}
              >
                -
              </button>
              <span className={quantity}>{this.props.line_item.quantity}</span>
              <button
                className={quantityUpdate}
                onClick={() => this.incrementQuantity(this.props.line_item.id)}
              >
                +
              </button>
            </div>

            <div className={price}>
              $
              {(
                this.props.line_item.quantity *
                this.props.line_item.variant.price
              ).toFixed(2)}{" "}
              <button
                className={remove}
                onClick={() =>
                  this.props.removeLineItemInCart(this.props.line_item.id)
                }
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default LineItem;
