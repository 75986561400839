import React, { Component } from "react";
import LineItem from "../LineItem/LineItem";
import Styles from "./Cart.module.css";

class Cart extends Component {
  openCheckout = () => {
    this.props.checkoutClicked();
    window.open(this.props.checkout.webUrl);
  };

  render() {
    const {
      cart,
      cart__close,
      cart__open,
      cart__header,
      cart__lineItems,
      cart__footer,
      cartInfo,
      cartInfo__total,
      cartInfo__small,
      cartInfo__pricing,
      pricing,
      cart__checkout,
      button
    } = Styles;

    let line_items = this.props.checkout.lineItems.map(line_item => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });

    return (
      <div
        className={`${cart} ${this.props.isCartOpen ? `${cart__open}` : ""}`}
      >
        <header className={cart__header}>
          <h2>Your cart</h2>
          <button onClick={this.props.handleCartClose} className={cart__close}>
            ×
          </button>
        </header>
        <ul className={cart__lineItems}>{line_items}</ul>
        <footer className={cart__footer}>
          <div className={`${cartInfo} clearfix`}>
            <div className={`${cartInfo__total} ${cartInfo__small}`}>
              Subtotal
            </div>
            <div className={cartInfo__pricing}>
              <div className={pricing}>
                $ {this.props.checkout.subtotalPrice}
              </div>
            </div>
          </div>
          <button
            className={`${cart__checkout} ${button}`}
            onClick={this.openCheckout}
          >
            Checkout
          </button>
        </footer>
      </div>
    );
  }
}

export default Cart;
