import React from "react";
import Styles from "./MobileFooter.module.css";
import { Link } from "react-router-dom";
import Socials from "../../Socials/Socials";

const MobileFooter = props => {
  return (
    <footer className={Styles.footerContainer}>
      <div className={Styles.footerLinks}>
        <Link className={Styles.navLink} to="/">
          home
        </Link>
        <div className={Styles.sec}>
          <Link className={Styles.navLink} to="/shop">
            shop
          </Link>
          <Link className={Styles.navLink} to="/about">
            mattress
          </Link>
        </div>
        <div className={Styles.sec}>
          <Link className={Styles.navLink} to="/faqs">
            faqs
          </Link>
          <Link className={Styles.navLink} to="/warranty">
            warranty
          </Link>
        </div>
        <hr className={Styles.divider} />

        <div className={`${Styles.sec} ${Styles.secBottom}`}>
          <Link className={Styles.navLink} to="/contact">
            contact
          </Link>
        </div>
        <Socials />
      </div>
    </footer>
  );
};
export default MobileFooter;
