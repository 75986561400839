import React from "react";
import Styles from "./Testimonials.module.css";
import Testimonial from "./Testimonial";
import Media from "react-media";

import RachelDavid from "../../assets/profile_images/racheldavid.jpg";
import ChelseyTaylor from "../../assets/profile_images/_chelseytaylor.jpg";
import KyleWilkie from "../../assets/profile_images/kaywilkie.jpg";
import Amz from "../../assets/profile_images/amzo6_.jpg";

const Testimonials = props => {
  return (
    <main className={Styles.testimonialContainer}>
      <h2 className={Styles.heading}>What they say</h2>
      <section className={Styles.testimonialSection}>
        <Media query="(max-width: 599px)">
          {matches =>
            matches ? (
              <React.Fragment>
                <Testimonial
                  text="I love my NOD mattress.  It’s the perfect mixture of firm and soft.  It is in my guest room and so far everyone who’s slept on it has commented on what a great experience they’ve had sleeping!"
                  author="Rachel David"
                  image={RachelDavid}
                />
                {/* <div className={Styles.testimonials}>
                <Testimonial>
                  <h4 className={Styles.more}>And more..</h4>
                </Testimonial>
              </div> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={Styles.testimonials}>
                  <Testimonial
                    text="I love my NOD mattress.  It’s the perfect mixture of firm and soft.  It is in my guest room and so far everyone who’s slept on it has commented on what a great experience they’ve had sleeping!"
                    author="Rachel David"
                    image={RachelDavid}
                  />
                  <Testimonial
                    text="I love Nod Mattress, the website was easy to use,  the delivery was fast, and the bed expands within seconds! Being a full-time mom with three kids, I was dreading having to go shopping and try a bunch of mattresses, the fact that Nod mattress shipped right to my door was a huge lifesaver!"
                    author="Chelsey Taylor"
                    image={ChelseyTaylor}
                  />
                  <Testimonial
                    text="Purchasing a Nod mattress was one of the best things I ever did. Feeling fully resting during my day has improved my life immensely."
                    author="Kyle Wilkie"
                    image={KyleWilkie}
                  />
                  <Testimonial
                    text="Had to replace a 20 year old mattress and thought to give this a try. Very pleased with the purchase. They have a really good return policy so not a huge risk if I didn’t end up liking the mattress. Firmness is perfect and reasonably priced."
                    author="Amz"
                    image={Amz}
                  />
                </div>
              </React.Fragment>
            )
          }
        </Media>
      </section>
    </main>
  );
};

export default Testimonials;
