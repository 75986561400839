import React from "react";
import Styles from "./BurgerMenu.module.css";
import { withRouter } from "react-router-dom";
import Drawer from "./Drawer/Drawer";

class BurgerMenu extends React.Component {
  state = {
    isVisible: false
  };

  handleClose = () => {
    this.setState({ isVisible: false });
  };

  handleOpen = () => {
    this.setState({ isVisible: true });
  };

  handleLinkClicked = () => {
    this.setState({ isVisible: false });
  };

  menuBtn = <div onClick={this.handleOpen} className={Styles.menuBtn} />;

  componentDidMount() {
    const { history } = this.props;

    history.listen((location, action) => {
      // location is an object like window.location
      this.setState({ isVisible: false });
    });
  }

  render() {
    const { isVisible } = this.state;

    const content = isVisible ? (
      <Drawer closed={this.handleClose} linkClicked={this.handleLinkClicked} />
    ) : (
      this.menuBtn
    );

    return content;
  }
}

export default withRouter(BurgerMenu);
