import React from "react";
import Styles from "./Dimensions.module.css";

const Dimensions = props => {
  const { sleepSize, weight } = props;
  const { dimensions, headers, values } = Styles;

  return (
    <div className={dimensions}>
      <div className={headers}>
        <p>Ship Size: </p>
        <p>Sleep Size:</p>
        <p>Weight:</p>
      </div>

      <div className={values}>
        <p>44" x 18" x 18"</p>
        <p>{sleepSize}</p>
        <p>{weight} kgs</p>
      </div>
    </div>
  );
};

export default Dimensions;
