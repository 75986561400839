import React from "react";
import Styles from "./Footer.module.css";
import FooterLink from "./FooterLink";
import Logo from "../../assets/nodLogo.svg";
import Socials from "../Socials/Socials";
import { Link } from "react-router-dom";

const Footer = props => {
  return (
    <footer className={Styles.footerContainer}>
      <Link className={Styles.logo} to="/" alt="nav logo">
        <img className={Styles.logo} src={Logo} alt="nod logo" />
      </Link>

      <div className={Styles.footerLinks}>
        <strong className={Styles.heading}>About</strong>
        <FooterLink uri="/about" text="Mattress" />
        <FooterLink uri="/shop" text="Shop" />
      </div>

      <div className={Styles.footerLinks}>
        <strong className={Styles.heading}>Support</strong>
        <FooterLink uri="/contact" text="Contact Us" />
        <FooterLink uri="/warranty" text="Warranty" />
      </div>

      <div
        className={`${Styles.footerLinks} ${Styles.socials}`}
        id={Styles.footerIconContainer}
      >
        <strong className={Styles.heading}>Get us on social</strong>
        <Socials />
      </div>
    </footer>
  );
};

export default Footer;
