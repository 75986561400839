import React from "react";
import Styles from "./Features.module.css";
import Feature from "./Feature"
import truckImg from "../../assets/feature_icons/truck.png";
import scissImg from "../../assets/feature_icons/sciss.png";
import stampImg from "../../assets/feature_icons/stamp.png";
import flagImg from "../../assets/feature_icons/flag.png";

const Features = props => {
  return <div className={Styles.features}>
      <Feature icon={truckImg} heading="Free Shipping" subText="Skip the drama of shopping. Get your Nod straight to your doorstep." />
      <Feature icon={scissImg} heading="Prices" subText="You spend a third of your life sleeping, so it's time to get a mattress comfortable for you and your wallet." />
      <Feature icon={stampImg} heading="101 Nights" subText="101 nights on us! If you don't love your Nod, no biggie. Try us out or we'll pick it up and give you a full refund." />
      <Feature icon={flagImg} heading="Canadian Made" subText="Designed in the province of British Columbia, each Nod mattress is carefully crafted using only the finest quality Canadian materials available." />
    </div>;
};

export default Features;
