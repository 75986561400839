import React from "react";
import Styles from "./Testimonials.module.css";
import profileImg from "../../assets/userdummy.png";

const Testimonial = props => {
  return (
    <div className={Styles.testimonial}>
      <p className={Styles.testimonialText}>{props.text}</p>
      <span className={Styles.author}>{props.author}</span>
      <img
        src={props.image}
        className={Styles.profileImg}
        alt="testimonial author"
      />
    </div>
  );
};

Testimonial.defaultProps = {
  text:
    "I love Nodding off on my Nod mattress. It's like sleeping in a pile of kittens.",
  author: "Happy Noddee",
  image: profileImg
};

export default Testimonial;
