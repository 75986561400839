import React from "react";
import Styles from "./ContactItem.module.css";

const ContactItem = props => {
  return (
    <div className={Styles.contactItem}>
      <h2 className={Styles.contactTitle}>{props.title}</h2>
      <span>
        {props.lines.map((line, index) => (
          <p key={index} className={Styles.item}>
            {line}
          </p>
        ))}
      </span>
    </div>
  );
};

export default ContactItem;
