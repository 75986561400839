import React from "react";
import "./Spinner.css";

const Spinner = props => {
  return (
    <div className="spinner-container">
      <div className="lds-hourglass" />
      <div className="spinner-text">{props.text}</div>
    </div>
  );
};

Spinner.defaultProps = { text: "Loading" };

export default Spinner;
