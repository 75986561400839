import React from "react";
import "./Diagram.css";
import diagramImg from "../../assets/mattressDiagram.svg";

const Diagram = () => {
  return (
    <div className="diagram__container">
      <div className="text">
        <ol className="diagram__list">
          <li>
            CertiPUR-US® certified <br />
            Gel Memory Foam
          </li>
          <ul className="details">
            <li>
              Gel Memory Foam conforms to every curve of the body for better
              support, pressure point relief and even weight distribution while
              also reducing the sleep surface temperature providing a luxurious
              comfortable sleep
            </li>
          </ul>
          <li> Copper Woven Fabric</li>
          <ul className="details">
            <li>
              Copper in the sleeping surface offers antimicrobial properties,
              helps in the formation of collagen, increases the absorption of
              iron and plays a role in energy production while conducting heat
              away from the body to aid in temperature regulation
            </li>
          </ul>
          <li> Soy Memory Foam</li>
          <ul className="details">
            <li>
              We care about environmental sustainability and use CertiPUR-US®
              certifed foam. Find out more about the benefits{" "}
              <a href="https://certipur.us/">here.</a>
            </li>
          </ul>
        </ol>
      </div>
      <div className="img__container">
        <h1 className="header"> The Nod Mattress</h1>
        <img className="image" src={diagramImg} alt="diagram" />
      </div>
    </div>
  );
};

export default Diagram;
