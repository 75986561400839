import React from "react";
import logo from "../../assets/nodLogo.svg";
import { Link } from "react-router-dom";
import Styles from "./NavLink.module.css";

const NavLink = props => {
  return (
    <Link className={Styles.logoContainer} to="/" alt="nav logo">
      <img src={logo} className={Styles.logo} alt="logo" />
    </Link>
  );
};

export default NavLink;
