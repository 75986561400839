import React from "react";
import Styles from "./ContactInfo.module.css";
import ContactItem from "../ContactItem/ContactItem";

const ContactInfo = props => {
  return (
    <div className={Styles.contactInfo}>
      <div className={Styles.infoContainer}>
        <h1 className={Styles.heading}>Let's talk today</h1>
        <ContactItem
          title="Address"
          lines={["150 12811 Clarke Pl", "Richmond, BC", "V6V 2H9, Canada"]}
        />
        <ContactItem
          title="Contact"
          lines={[
            // "Phone Us:",
            // <a href="tel:+1 858 123 4567" className={Styles.contactLink}>
            //   858.123.4567
            // </a>,
            "Email Us:",
            <a
              href="mailto:hello@nodmattress.com"
              className={Styles.contactLink}
            >
              hello@nodmattress.com
            </a>
          ]}
        />
        <ContactItem
          title="Social Media"
          lines={[
            <a
              href="https://www.facebook.com/nodmattress"
              target="_blank"
              rel="noopener noreferrer"
              className={Styles.contactLink}
            >
              Facebook
            </a>,
            <a
              href="https://www.instagram.com/nodmattress"
              target="_blank"
              rel="noopener noreferrer"
              className={Styles.contactLink}
            >
              Twitter
            </a>,
            <a
              href="https://twitter.com/nodmattress"
              target="_blank"
              rel="noopener noreferrer"
              className={Styles.contactLink}
            >
              Instagram
            </a>
          ]}
        />
      </div>
    </div>
  );
};
export default ContactInfo;
