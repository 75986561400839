import React from "react";
import Styles from "./ContactForm.module.css";

const ContactForm = props => {
  const { changed, submitted, isSent } = props;

  if (isSent) {
    return <div className={Styles.contactForm}>
      <h1 className={Styles.heading}>Message Sent Thank you</h1>
      <p className={Styles.subHeading}>
        You'll Hear from us soon!
      </p>
    </div>;
  }

  return (
    <div className={Styles.contactForm}>
      <h1 className={Styles.heading}>Contact Form</h1>
      <p className={Styles.subHeading}>
        Fill the form below and we'll get back to you as soon as possible,
        usually less than 24 hours.
      </p>
      <form onSubmit={submitted} className={Styles.fieldRow}>
        <label htmlFor="contactName">Your name</label>
        <input
          onChange={changed}
          id="contactName"
          name="contactName"
          type="text"
        />
        <label htmlFor="contactPhone">Your phone</label>
        <input
          onChange={changed}
          id="contactPhone"
          name="contactPhone"
          type="text"
        />
        <label htmlFor="contactMessage">Your message</label>
        <textarea
          onChange={changed}
          id="contactMessage"
          name="contactMessage"
          rows="4"
          cols="50"
        />
        <button className={Styles.submit}>Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;
