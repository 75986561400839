import React from "react";
import "./Banner.css";
import scissImg from "../../assets/feature_icons/sciss.png";
import flagImg from "../../assets/feature_icons/flag.png";

const Banner = () => {
  return (
    <div className="banner__container">
      <div className="image__container">
        <img className="banner__image" src={scissImg} alt="scissors" />
      </div>
      <div className="banner__middle">
        <h2 className="banner__text-top">
          Why go to a store? Affordability and high quality on{" "}
          <span style={{ color: "var(--brand-yellow)" }}>DELIVERY</span>.
        </h2>
        <hr />
        <h2 className="banner__text-bottom">
          <span style={{ color: "var(--brand-yellow)" }}>ENTIRELY</span>{" "}
          Canadian made. Shop local, Nod off wherever you want.
        </h2>
      </div>
      <div className="image__container">
        <img className="banner__image" src={flagImg} alt="flag" />
      </div>
    </div>
  );
};

export default Banner;
