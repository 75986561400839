import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../Faqs/Faq/Faq.css";
import Styles from "../../Components/Contact/ContactInfo/ContactInfo";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const { question, answer, link, mailto } = this.props;

    return (
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={this.handleChange("panel4")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h2 className="question">Q: {question}</h2>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <hr />
              <p className="answer">
                A: {answer}
                {/* {this.props.link} */}
                {link && (
                  <React.Fragment>
                    <br />
                    <Link to={link.to} alt={link.alt}>
                      {link.name}
                    </Link>
                  </React.Fragment>
                )}
                {mailto && (
                  <React.Fragment>
                    <br />
                    <a href={mailto.to} className={Styles.contactLink}>
                      {mailto.name}
                    </a>
                  </React.Fragment>
                )}
              </p>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

ControlledExpansionPanels.defaultProps = {
  question: "This is Question",
  answer: "This is Answer"
};

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ControlledExpansionPanels);
