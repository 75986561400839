import React from "react";
import Styles from "./Drawer.module.css";
import closeSVG from "../../../assets/material_icons/close.svg";
import MobileFooter from "../../../Components/Footer/MobileFooter/MobileFooter";

const Drawer = props => {
  return (
    <React.Fragment>
      <div className={Styles.container}>
        <img
          onClick={props.closed}
          className={Styles.close}
          src={closeSVG}
          alt="Close Button"
        />
        <MobileFooter />
      </div>
      <div className={Styles.closer} onClick={props.closed} />
    </React.Fragment>
  );
};

export default Drawer;
