import React from "react";
import "./ShopLink.css";
import { Link } from "react-router-dom";
import ShopIcon from "../../../assets/material_icons/shopping_cart_empty.svg";

const ShopLink = () => {
  return (
    <Link to="/shop" className="shopLinkWrapper">
      <img src={ShopIcon} alt="Shop" className="shopLinkIcon" />
    </Link>
  );
};

export default ShopLink;
