import React from "react";
import Styles from "./VariantSelector.module.css";

const VariantSelector = props => {
  const { variants } = props.product;

  const measurements = [
    '39" x 75" x 10"',
    '39" x 80" x 10"',
    '54" x 75" x 10"',
    '60" x 80" x 10"',
    '76" x 80" x 10"',
    '72" x 84" x 10"'
  ];

  return (
    <select
      className={Styles.productSelect}
      name="Size"
      onChange={props.handleOptionChange}
    >
      {variants.map((variant, i) => {
        return (
          <option value={variant.title} key={`Size-${variant.title}`}>
            {variant.title} — ${variant.price} — {measurements[i]}
          </option>
        );
      })}
    </select>
  );
};

export default VariantSelector;
