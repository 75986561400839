import React from "react";
import NavLink from "./NavLink";
import Logo from "./Logo";
import Styles from "./NavLink.module.css";

const NavLinks = props => {
  return (
    <div className={`${Styles.nav} `}>
      <div className={Styles.leftLinks}>
        <NavLink uri="/about" text="mattress" />
        <NavLink uri="/shop" text="shop" />
      </div>
      <div className={Styles.logoContainer}>
        <Logo />
      </div>
      <div className={Styles.rightLinks}>
        <NavLink uri="/faqs" text="faqs" />
        <NavLink uri="/contact" text="contact" />
      </div>
    </div>
  );
};

export default NavLinks;
