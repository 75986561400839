import React from "react";
import Spinner from "../../Shared/Spinner/Spinner";
import "./Verifier.css";

class Verifier extends React.Component {
  componentDidMount() {
    setInterval(() => this.checkCheckoutStatus(), 8000);
  }

  checkCheckoutStatus() {
    const checkoutId = this.props.checkout.id;

    this.props.client.checkout.fetch(checkoutId).then(checkout => {
      // if checkout is complete
      if (checkout.completedAt) {
        window.location.href = "/order_complete";
      }
    });
  }
  handleClick = () => {
    window.location.reload();
  };
  render() {
    return (
      <div className="background">
        <div className="spinner">
          <Spinner text="Verifiying Purchase..." />
          <div>
            <button className="cancel" onClick={this.handleClick}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Verifier;
