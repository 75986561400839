import React from "react";
import ReactDOM from "react-dom";
import Client from "shopify-buy";
import "./index.css";
import App from "./App";
import firebase from "firebase/app";
import * as serviceWorker from "./serviceWorker";

const client = Client.buildClient({
  storefrontAccessToken: "2867d19d8ee0e66bd0250cc38ea546cc",
  domain: "nod-mattress-corp.myshopify.com"
});

const config = {
  apiKey: "AIzaSyDxwKxE3Gj4DMq1ZtXfeQej5FAY2lc2pK4",
  authDomain: "nod-mattress.firebaseapp.com",
  databaseURL: "https://nod-mattress.firebaseio.com",
  projectId: "nod-mattress",
  storageBucket: "nod-mattress.appspot.com",
  messagingSenderId: "715062214787"
};

firebase.initializeApp(config);
firebase
  .auth()
  .signInAnonymously()
  .catch(error => {
    const { code, message } = error;
    console.error(`Auth failed with code: ${code}, ${message}`);
  });

ReactDOM.render(<App client={client} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
