import React from "react";
import Styles from "./Shop.module.css";
import Selector from "./Components/Selector/Selector";
import SelectorGroup from "./Components/SelectorGroup/SelectorGroup";
import Dimensions from "./Components/Dimensions/Dimensions";
import Media from "react-media";
import Spacer from "../../Components/Shared/Spacer/Spacer";
import Spinner from "../../Components/Shared/Spinner/Spinner";

class Shop extends React.Component {
  state = {
    mattressHandle: "testmatt01",
    curSelection: "twin",
    sleepSize: {
      twin: `39" x 75" x 10"`,
      "twin xl": `39" x 80" x 10"`,
      full: `54" x 75" x 10"`,
      queen: `60" x 80" x 10"`,
      king: `76" x 80" x 10"`,
      "cal king": `72" x 84" x 10"`
    }
  };

  shopMobile = () => {
    const { curSelection, mattressHandle, sleepSize } = this.state;
    const { products, addVariantToCart } = this.props;

    if (!products || products.length <= 0) {
      return <Spinner />;
    }

    // get variants of our product
    const variants = products.filter(
      product => product.handle === mattressHandle
    )[0].variants;

    const curVariant = variants
      .filter(variant => variant.title === curSelection)
      .reduce(variant => variant);

    const {
      container,
      productImg,
      price,
      currency,
      chooseText,
      buyBtn,
      bottomTextContainer
    } = Styles;

    return (
      <React.Fragment>
        <Spacer
          fontColor="#fff"
          color="var(--brand-mid-grey)"
          text="Shop"
          size="2em"
          height="50px"
        />
        <div className={container}>
          <div className={Styles.mobileTopTextContainer}>
            <h1 className={Styles.mobileTopTextHeader}> The Nod Mattress</h1>
            <p className={Styles.mobileTopTextDescription}>
              Our mattresses are made with CertiPUR-US® certified gel and memory
              foam, and then sewn together with copper woven fabric to ensure
              the greatest rest that you can get, on all Canadian made
              materials.
            </p>
          </div>
          <div className={productImg} />
          <div className={bottomTextContainer}>
            <p className={Styles.intro}>Nod Mattresses lead to Noddy nights.</p>
            <hr className={Styles.ruler} />
            <p className={chooseText}>Choose your Size:</p>
            <SelectorGroup>
              {variants.map(variant => (
                <Selector
                  clicked={this.onSelectionClicked}
                  key={variant.title}
                  name={variant.title}
                  selected={variant.title === curSelection}
                />
              ))}
            </SelectorGroup>
            <Dimensions
              weight={curVariant.weight}
              sleepSize={sleepSize[curVariant.title]}
            />
            <div className={price}>
              ${curVariant.price} <span className={currency}>CAD</span>
            </div>
            <button
              onClick={() => addVariantToCart(curVariant.id, 1)}
              className={buyBtn}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  shopDesktop = () => {
    const { curSelection, mattressHandle, sleepSize } = this.state;
    const { products, addVariantToCart } = this.props;

    if (!products || products.length <= 0) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    // get variants of our product
    const variants = products.filter(
      product => product.handle === mattressHandle
    )[0].variants;

    const curVariant = variants
      .filter(variant => variant.title === curSelection)
      .reduce(variant => variant);

    const {
      container,
      productImg,
      price,
      currency,
      header,
      chooseText,
      buyBtn,
      leftColumn,
      rightColumn,
      buyContainer
    } = Styles;

    return (
      <div className={container}>
        <div className={leftColumn}>
          <div className={buyContainer}>
            <p className={Styles.intro}>Nod Mattresses lead to Noddy nights.</p>
            <hr className={Styles.ruler} />
            <p className={Styles.description}>
              {" "}
              Our mattresses are made with CertiPUR-US® certified gel and memory
              foam, and then sewn together with copper woven fabric to ensure
              the greatest rest that you can get, on all Canadian made
              materials.
            </p>
            <p className={chooseText}>Choose your Size:</p>
            <SelectorGroup>
              {variants.map(variant => (
                <Selector
                  clicked={this.onSelectionClicked}
                  key={variant.title}
                  name={variant.title}
                  selected={variant.title === curSelection}
                />
              ))}
            </SelectorGroup>
            <Dimensions
              weight={curVariant.weight}
              sleepSize={sleepSize[curVariant.title]}
            />
            <div className={price}>
              ${curVariant.price} <span className={currency}>CAD</span>
            </div>

            <button
              onClick={() => addVariantToCart(curVariant.id, 1)}
              className={buyBtn}
            >
              Add to Cart
            </button>
          </div>
        </div>
        <div className={rightColumn}>
          <h1 className={header}>The Nod Mattress.</h1>
          <div className={productImg} />
        </div>
      </div>
    );
  };

  onSelectionClicked = selectionName =>
    this.setState({ curSelection: selectionName });

  render() {
    return (
      <Media query="(min-width: 700px)">
        {matches => (matches ? this.shopDesktop() : this.shopMobile())}
      </Media>
    );
  }
}

export default Shop;
