import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Styles from "./App.module.css";
import Footer from "./Components/Footer/Footer";
import MobileFooter from "./Components/Footer/MobileFooter/MobileFooter";
import ShopBar from "./Components/ShopBar/ShopBar";
import NavBar from "./Components/NavBar/NavBar";
import BurgerMenu from "./Containers/BurgerMenu/BurgerMenu";
import NavLinks from "./Components/NavBar/NavLinks";
import IndexPage from "./Pages/IndexPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import WarrantyPage from "./Pages/WarrantyPage/WarrantyPage";
import OrderCompletePage from "./Pages/OrderCompletePage";
import FaqsPage from "./Pages/FaqsPage/FaqsPage";
import ShopPage from "./Pages/ShopPage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import Verifier from "./Components/Shopify/Verifier/Verifier";
import Cart from "./Components/Shopify/Cart/Cart";
import ShopLink from "./Components/Shared/ShopLink/ShopLink";
import Media from "react-media";
import firebase from "firebase/app";

class App extends Component {
  constructor(props) {
    super(props);

    this.firestore = firebase.firestore();
    const settings = { /* your settings... */ timestampsInSnapshots: true };
    this.firestore.settings(settings);

    // As it stands this construct only really exists to set up Shopify
    this.state = {
      isCartOpen: false,
      mattressHandle: "testmatt01",
      checkout: { lineItems: [] },
      products: [],
      isProductsLoaded: false,
      shop: {},
      isCheckingOut: false
    };

    this.handleCheckoutClicked = this.handleCheckoutClicked.bind(this);
    this.handleCartClose = this.handleCartClose.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
  }

  getCookie = name => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length === 2)
      return parts
        .pop()
        .split(";")
        .shift()
        .split(" ")
        .shift();
  };

  async createCart(uid) {
    try {
      const checkout = await this.props.client.checkout.create();
      this.setState({ checkout });
      this.firestore
        .collection("checkouts")
        .doc(uid)
        .set({ checkoutId: checkout.id });
    } catch (error) {
      console.error(error.message);
    }
  }

  async getCart(id, uid) {
    try {
      let checkout = await this.props.client.checkout.fetch(id);
      if (!checkout) {
        throw new Error("No checkout found");
      }
      checkout.completedAt
        ? this.createCart(uid) // cart was completed -> create new cart
        : this.setState({
            checkout
          });
    } catch (error) {
      this.createCart(uid);
    }
  }

  componentDidMount() {
    // Get data from shopify
    this.props.client.product.fetchAll().then(res => {
      this.setState({
        products: res,
        isProductsLoaded: true
      });
    });

    this.props.client.shop.fetchInfo().then(res => {
      this.setState({
        shop: res
      });
    });

    // Subscribe to auth changes.
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // checkoutID from firebase
        this.firestore
          .collection("checkouts")
          .doc(user.uid)
          .get()
          .then(data => {
            if (data.exists) {
              this.getCart(data.data().checkoutId, user.uid);
            } else {
              // Create a new cart
              this.createCart(user.uid);
            }
          })
          .catch(console.error);
      } else {
        // User is signed out
      }
    });
  }

  addVariantToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true
    });

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then(res => {
        this.setState({
          checkout: res
        });
      });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) }
    ];

    return this.props.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then(res => {
        this.setState({
          checkout: res
        });
      });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then(res => {
        this.setState({
          checkout: res
        });
      });
  }

  handleCartClose() {
    this.setState({
      isCartOpen: false
    });
  }

  handleCheckoutClicked() {
    this.setState({ isCheckingOut: true });
  }

  render() {
    const {
      products,
      mattressHandle,
      isProductsLoaded,
      checkout,
      isCheckingOut
    } = this.state;
    const { client } = this.props;
    const { cart, cartWrapper } = Styles;
    const { lineItems } = this.state.checkout;
    const showCartIcon = lineItems.length > 0;
    const mattressProduct = products.filter(
      product => product.handle === mattressHandle
    )[0];

    return (
      <Router>
        <React.Fragment>
          <BurgerMenu />
          <header>
            <NavBar>
              {!this.state.isCartOpen && showCartIcon && (
                <div className={cartWrapper}>
                  <button
                    className={cart}
                    onClick={() =>
                      this.setState({
                        isCartOpen: true
                      })
                    }
                  />
                </div>
              )}
              <NavLinks />
            </NavBar>
            {isProductsLoaded && (
              <ShopBar
                product={mattressProduct}
                client={client}
                addVariantToCart={this.addVariantToCart}
              />
            )}
            {isCheckingOut && <Verifier client={client} checkout={checkout} />}
            <Cart
              checkout={this.state.checkout}
              checkoutClicked={this.handleCheckoutClicked}
              isCartOpen={this.state.isCartOpen}
              handleCartClose={this.handleCartClose}
              updateQuantityInCart={this.updateQuantityInCart}
              removeLineItemInCart={this.removeLineItemInCart}
            />
            <ShopLink />
          </header>
          <main>
            <Switch>
              <Route exact path="/" component={IndexPage} />
              <Route
                exact
                path="/contact"
                render={props => <ContactPage {...props} />}
              />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/faqs" component={FaqsPage} />
              <Route exact path="/warranty" component={WarrantyPage} />
              <Route
                exact
                path="/order_complete"
                render={props => (
                  <OrderCompletePage {...props} checkout={checkout} />
                )}
              />
              <Route
                exact
                path="/shop"
                render={props => (
                  <ShopPage
                    {...props}
                    products={this.state.products}
                    addVariantToCart={this.addVariantToCart}
                  />
                )}
              />
            </Switch>
          </main>
          <Media query="(min-width: 600px)">
            {matches => (matches ? <Footer /> : <MobileFooter />)}
          </Media>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
