import React from "react";
import Styles from "./Spacer.module.css";

const Spacer = props => {
  const { fontColor, color, text, size, display, weight } = props;

  const spacerStyle = {
    backgroundColor: color,
    display
  };

  const textStyle = {
    color: fontColor,
    fontFamily: `var(--display-font), sans-serif`,
    fontSize: size,
    fontWeight: weight
  };

  return (
    <div style={spacerStyle} className={Styles.spacer}>
      <div style={textStyle} className={Styles.spacerText}>
        {text}
      </div>
    </div>
  );
};

export default Spacer;
