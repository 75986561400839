import React from "react";
import Styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const FooterLink = props => {
  return (
    <Link to={props.uri} className={Styles.footerLink}>
      {props.text}
    </Link>
  );
};

export default FooterLink;
