import React from "react";
import { Link } from "react-router-dom";
import Styles from "./NavLink.module.css";

const NavLink = props => {
  const { uri, text } = props;

  return (
    <Link className={Styles.navLink} to={uri}>
      {text}
    </Link>
  );
};

export default NavLink;
