import React from "react";
import MainImage from "../Components/Media/MainImage/MainImage";
import DeliveryImage from "../Components/Media/DeliveryImage/DeliveryImage";
import Spacer from "../Components/Shared/Spacer/Spacer";
import Features from "../Components/Features/Features";
import Testimonials from "../Components/Testimonials/Testimonials";

const IndexPage = props => {
  return (
    <React.Fragment>
      <MainImage />
      <Spacer
        fontColor="#fff"
        color="#333"
        text={`Sleep well with these features`}
        size="1.5em"
        height="50px"
        display="block"
      />
      <Features />
      <DeliveryImage />
      <Spacer
        fontColor="#000"
        color="#eee"
        text={`Free shipping direct to your door`}
        size="1.5em"
        height="50px"
      />
      <Testimonials />
    </React.Fragment>
  );
};

export default IndexPage;
