const FaqData = [
  {
    question:
      "Can I specify a delivery date? If so, when should I place my order?",
    answer:
      "Unfortunately, you cannot specify a delivery date however you should expect to have your Nod Mattress arrive within 5-15 business days."
  },
  {
    question: "When can I expect to receive my Nod products?",
    answer: "5-15 business days."
  },
  {
    question: "Tell me more about the 101 Night Trial.",
    answer:
      "Please click this link for further education regarding the 101 Night Trail.",
    link: {
      to: "/warranty",
      name: "Warranty Page",
      alt: "warranty"
    }
  },
  {
    question: "How do I know which mattress is right for me?",
    answer:
      "Nod Mattress is currently one style of mattress, therefore which mattress is right for you? It would be a Noddy Nod mattress so click the link beyond to buy now!",
    link: {
      to: "/shop",
      name: "Shop Page",
      alt: "shop"
    }
  },
  {
    question: "What size mattresses do you make?",
    answer: "We manufacture twin, twin xl, full, queen, king, Cal King."
  },
  {
    question: "Does the Nod Mattress sleep hot?",
    answer:
      "Gel infused memory foam is within our Nod Mattresses, this helps reduce the sleep surface temperature providing a luxuriously comfortable sleep."
  },
  {
    question: "How do I unbox my Nod Mattress?",
    answer:
      "Bring your Nod Mattress to your area where the bed will lay.  Simply open the box and pull out your Nod Mattress, after this carefully open the plastic wrap and watch your Nod Mattress decompress to its original size and get ready to jump into a glorious adventure of ridiculous comfort with your Nod Mattress."
  },
  {
    question: "How do I return my Nod Mattress?",
    answer:
      "Contact us through email and we will work with you to help you through this process.",
    mailto: {
      to: "mailto:hello@nodmattress.com",
      name: "hello@nodmattress.com"
    }
  },
  {
    question: "What is the typical shipping process?",
    answer:
      "After the Nod Mattress is purchased, you will be notified via email on the status of your shipment."
  }
];

export default FaqData;
