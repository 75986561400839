import React from "react";
import ContactInfo from "../../Components/Contact/ContactInfo/ContactInfo";
import ContactForm from "../../Components/Contact/ContactForm/ContactForm";
import Styles from "./ContactPage.module.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Spacer from "../../Components/Shared/Spacer/Spacer";

class ContactPage extends React.Component {
  state = {
    contactForm: {},
    isSent: false
  };

  componentDidMount() {
    this.db = firebase.firestore();
    const settings = { /* your settings... */ timestampsInSnapshots: true };
    this.db.settings(settings);
  }

  handleChange(event) {
    const { contactForm } = this.state;
    const { name, value } = event.target;

    // anything in this statement needs to be an integer
    if (name === "contactPhone") {
      contactForm[name] = parseInt(value, 10);
    } else {
      // all of these are strings
      contactForm[name] = value;
    }

    this.setState({ contactForm });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!firebase.auth().currentUser) {
      console.error("No session / Invalid Session");
      return;
    }

    const { contactForm } = this.state;
    this.db
      .collection("enquiries")
      .add(contactForm)
      .then(docRef => {
        // reset contact form
        document.getElementById("contactName").value = "";
        document.getElementById("contactPhone").value = "";
        document.getElementById("contactMessage").value = "";
        this.setState({ contactForm: {}, isSent: true });
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  }

  render() {
    const { isSent } = this.state;
    return (
      <React.Fragment>
        <Spacer
          fontColor="#fff"
          color="var(--brand-mid-grey)"
          text="Get in Touch"
          size="2em"
          height="50px"
        />
        <div className={Styles.background}>
          <div className={Styles.contactOptions}>
            <ContactInfo />
            <ContactForm
              isSent={isSent}
              changed={e => {
                this.handleChange(e);
              }}
              submitted={e => {
                this.handleSubmit(e);
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactPage;
