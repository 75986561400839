import React from "react";
import Styles from "./Hows.module.css";

const { feature, featureHeading } = Styles;

const How = props => {
  return (
    <div className={feature}>
      <img src={props.icon} alt="feature icon" />
      <span className={featureHeading}>{props.heading}</span>
      <p>{props.subText}</p>
    </div>
  );
};

export default How;
