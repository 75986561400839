import React from "react";

const container = {
  height: "calc(100vh - 80px - 160px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
};

const header = {
  font: "2em var(--display-font)",
  textAlign: "center",
  marginBottom: "2rem"
};
const text = {
  font: "1em var(--display-font)",
  textAlign: "center"
};

const OrderCompletePage = () => {
  return (
    <div style={container}>
      <h1 style={header}>Thank you for your order!</h1>
      <p style={text}>Now prepare for Nod Off...</p>
    </div>
  );
};

export default OrderCompletePage;
