import React from "react";
import Styles from "./SelectorGroup.module.css";

const SelectorGroup = props => {
  return (
    <div className={Styles.selectorGroup}>
      {props.children}
    </div>
  );
}

export default SelectorGroup;
