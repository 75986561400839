import React from "react";
import Styles from "./MainImage.module.css";

const MainImage = props => {
  return (
    <div className={Styles.mainImage}>
      <h1 className={Styles.header}>Nod Mattress</h1>
      <h2 className={Styles.subHeader}>Have a Noddy Night</h2>
    </div>
  );
};

export default MainImage;
