import React from "react";
import Styles from "./Hows.module.css";
import Feature from "./How";
import needleImg from "../../assets/feature_icons/needle.png";
import moneysImg from "../../assets/feature_icons/moneys.png";
import truckImg from "../../assets/feature_icons/truck.png";
import houseImg from "../../assets/feature_icons/house.png";
import arrowImg from "../../assets/feature_icons/arrow.svg";

const Hows = props => {
  return (
    <div className={Styles.hows__container}>
      <h1 className={Styles.header}>How it works:</h1>
      <div className={Styles.features}>
        <Feature
          icon={moneysImg}
          heading="Purchase"
          subText="Nod Mattresses are made on demand, and don't sit around a warehouse."
        />
        <img src={arrowImg} className={Styles.arrow} alt="arrow" />
        <Feature
          icon={needleImg}
          heading="Made to Order"
          subText="Only the best Canadian materials are used to make our mattresses"
        />
        <img src={arrowImg} className={Styles.arrow} alt="arrow" />
        <Feature
          icon={truckImg}
          heading="Free Delivery"
          subText="From day of purchase to your house within 10-15 business days."
        />
        <img src={arrowImg} className={Styles.arrow} alt="arrow" />
        <Feature
          icon={houseImg}
          heading="To your Home"
          subText="Skip the drama of shopping. Get your Nod mattress straight to your doorstep."
        />
      </div>
    </div>
  );
};

export default Hows;
