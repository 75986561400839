import React from "react";
import Styles from "./Socials.module.css";
import facebook from "../../assets/social_icons/flogo-HexRBG-Wht-72.svg";
import instagram from "../../assets/social_icons/glyph-logo_May2016.png";
import twitter from "../../assets/social_icons/Twitter_Social_Icon_Square_White.svg";

const Socials = props => {
  return (
    <div className={Styles.container}>
      <a
        href="https://www.facebook.com/nodmattress"
        target="_blank"
        rel="noopener noreferrer"
        style={{ background: `url(${facebook}) center/cover no-repeat` }}
        alt={"facebook"}
      >
        <span>Facebook</span>
      </a>
      <a
        href="https://www.instagram.com/nodmattress"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          background: `url(${instagram}) center/cover no-repeat`
        }}
        alt={"instagram"}
      >
        <span>Instagram</span>
      </a>
      <a
        href="https://twitter.com/nodmattress"
        target="_blank"
        rel="noopener noreferrer"
        style={{ background: `url(${twitter}) center/cover no-repeat` }}
        alt={"twitter"}
      >
        <span>Twitter</span>
      </a>
    </div>
  );
};

export default Socials;
