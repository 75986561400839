import React from "react";
import Styles from "./WarrantyPage.module.css";
import Spacer from "../../Components/Shared/Spacer/Spacer";

const WarrantyPage = props => {
  return (
    <React.Fragment>
      <Spacer
        fontColor="#fff"
        color="var(--brand-mid-grey)"
        text="Warranty"
        size="2em"
        height="50px"
      />
      <div className={Styles.termsContainer}>
        <div className={Styles.term}>
          <h2 className={Styles.termHeader}>
            Everything you need to know about the Nod Mattress 10 Year Limited
            Warranty
          </h2>
          <p className={Styles.termContent}>
            A 10-year Limited Warranty covers your new Nod Mattress. For ten
            years following your Nod Mattress purchase, if you experience a
            manufacturing defect that is covered under our warranty, we will
            offer a one-time replacement or repair of the original mattress size
            purchased. This includes all components of the mattress. There is no
            fee or deductible required. If the warranty claim happens during
            your 101-night trial period, the trial will be paused during the
            shipment of the replacement mattress, and the trial will resume upon
            delivery of the replacement Nod Mattress.
          </p>
        </div>

        <div className={Styles.term}>
          <h2 className={Styles.termHeader}>What's Covered?</h2>
          <p className={Styles.termContent}>
            Nod’s limited warranty covers the following during normal use:
          </p>
          <ul>
            <li>
              Degradation, which causes the mattress to have visible indentation
              greater than one (1) inch that is not associated with an
              indentation or sag resulting from improper foundations or
              adjustable bed base.
            </li>
            <li>
              Nod Mattresses are designed to be on a hard surface (base, wooden
              slats, etc) if this is not followed, warranty is void.
            </li>
            <li>
              Any physical flaw in the mattress that causes the foam material to
              split or crack, despite proper handling under normal usage.
            </li>
            <li>
              Any defect or flaw in the zipper assembly of the mattress cover.
            </li>
            <li>
              Nod will require that you take photos and measurements of your Nod
              Mattress while processing a warranty claim.
            </li>
            <li>
              The law tag must not be removed from original product as it
              identifies the product for the purpose of the warranties.
            </li>
          </ul>
        </div>

        <div className={Styles.term}>
          <h1 className={Styles.termHeader}>What's Not Covered?</h1>
          <p className={Styles.termContent}>
            This limited warranty does not cover the following:
          </p>
          <ul>
            <li>A normal increase in mattress softness after long-term use.</li>
            <li>Comfort or mattress height preference.</li>
            <li>
              Any stains, burns, tears, cuts, or liquid/moisture damage caused
              by the customer or their environment.
            </li>
            <li>
              Nod Mattresses must not be used on floors susceptible to moisture
              build-up such as concrete, below-grade (basement), or on floors in
              humid environments. Such inappropriate use can result in mold
              growth and void the warranty.
            </li>

            <li>Damage due to use with inappropriate foundational support.</li>
            <li>
              Any product that is sold by an unauthorized retailer or product
              that is sold in any way as “used” or similar wording indicating
              the product is not “new”.
            </li>
            <li>
              Damage caused by commercial use, such as in a hotel or store.
            </li>
            <li>
              Mattress disfigurement caused by failure of unpacking and
              expanding the Nod Mattress within two (2) weeks of the date of
              delivery.
            </li>
          </ul>
          <p className={Styles.termContent}>
            No new warranty will be provided for any replacement product.
            Warranty claims are only applicable within the country of original
            delivery, to the original customer name.
          </p>
          <p className={Styles.termContent}>
            If you have any any questions or concerns reguarding the Terms and
            Conditions listed here please contact us and we'll be happy to
            answer any questions.
          </p>
        </div>
        <div className={Styles.term}>
          <h1 className={Styles.termHeader}>Your Responsibility</h1>
          <p className={Styles.termContent}>
            The Nod Mattress warranty covers the original purchaser of any Nod
            Mattress who is located in Canada. Any warranty, or implied
            warranty, is only valid for the period the original purchaser of the
            Mattress owns the Mattress. The original purchaser, for this
            warranty, will be the individual whose details are associated with
            the e-commerce purchase. Please retain a copy of the receipt which
            was sent to the email address on record.
          </p>
          <p className={Styles.termContent}>
            Photos, the lot # (located on the mattress), and original proof of
            purchase may be required upon a warranty request in order to
            identify and assess the warranty issue.
          </p>
        </div>
        <div className={Styles.term}>
          <h1 className={Styles.termHeader}>Replacements</h1>
          <p className={Styles.termContent}>
            Except for the terms provided above, Nod will not charge the
            purchaser to repair or replace a purchaser’s Mattress if it is
            deemed defective during the length of this 10-year Limited Warranty.
            No new warranty will be provided for the replacement product. The
            warranty approval process is up to Nod's sole discretion, and not up
            to the customer’s interpretation of our warranty policy.
          </p>
          <p className={Styles.termContent}>
            The product, opened or unopened, can only be returned within the
            101-Night Trial. In the event of a return, the pickup address can
            differ from the original delivery address. However, it must be at a
            valid street address within Canada. Any environmental and
            customer-inflicted damage will disqualify the ability to exchange or
            return.
          </p>
          <p className={Styles.termContent}>
            Free shipping is available to the ten provinces in Canada, and there
            is a non-refundable shipping surcharge for orders shipping out to
            the Canadian Territories. There is no fee or deductible required for
            the product, and transportation of any replacement is free to any
            provincial street address in Canada.
          </p>
          <p className={Styles.termContent}>
            If the incorrect size is ordered, a one-time size exchange will be
            offered within the 101-Night Trial. This must be communicated to Nod
            within the first 101 nights. To limit the impact on the environment,
            Nod will treat a size exchange as completion of the 101-Night Trial.
            It is strongly recommended to confirm the correct size required
            before an order is placed.
          </p>
        </div>
        <div className={Styles.term}>
          <h1 className={Styles.termHeader}>Returns</h1>
          <p className={Styles.termContent}>
            The product, opened or unopened, can only be returned within the
            101-Night Trial. In the event of a return, the pickup address can
            differ from the original delivery address, however it must be a
            valid street address within Canada. Any environmental and/or
            customer-inflicted damage will disqualify the ability to exchange or
            return. Additionally, to ensure our mattresses can be donated to
            charities, all returns must be in good condition and free of
            stains/damage.
          </p>
          <p className={Styles.termContent}>
            Any returned product type will not be eligible for additional
            subsequent trials (i.e. if you return a Mattress, you will not be
            eligible for another 101-night trial on future mattress orders).
          </p>
          <p className={Styles.termContent}>
            Once a return is requested, and the required information has been
            provided, you will be contacted directly by one of our pickup
            partners for your area to schedule an appointment. In urban centers,
            a partner should contact you within 2-5 business days. In rural
            areas, it may take up to 3 weeks. While our partners will do their
            best to accommodate your preferred times, pickups will ultimately be
            subject to their availability. A pickup must be completed within 3
            weeks after connecting with our pickup partners.
          </p>
          <p className={Styles.termContent}>
            Once the pickup has been completed and confirmed, your refund will
            be processed back onto the original card used for the purchase
            within 5 business days. Standard processing time for the funds to be
            posted by your bank are 3-5 business days.
          </p>
        </div>
        <div className={Styles.term}>
          <h1 className={Styles.termHeader}>
            Disclaimer, limitation on liability
          </h1>
          <p className={Styles.termContent}>
            TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, THE MATTRESS IS
            PROVIDED “AS IS” AND THIS WARRANTY AND ANY IMPLIED WARRANTIES ARE
            YOUR EXCLUSIVE WARRANTIES AND REPLACE ALL OTHER WARRANTIES OR
            CONDITIONS, EXPRESS OR IMPLIED.
          </p>
          <p className={Styles.termContent}>
            SOME PROVINCES OR TERRITORIES DO NOT ALLOW LIMITATIONS ON HOW LONG
            AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO
            YOU. SOME PROVINCES OR OTHER JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT
            APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM PROVINCE
            TO PROVINCE AND JURISDICTION TO JURISDICTION.
          </p>
          <p className={Styles.termContent}>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS SET
            FORTH IN THIS LIMITED MATTRESS WARRANTY, IN NO EVENT WILL NOD OR ITS
            SUPPLIERS BE LIABLE FOR PROCUREMENT OF SUBSTITUTE PRODUCTS OR OTHER
            SPECIAL, INCIDENTAL, CONSEQUENTIAL OR INDIRECT DAMAGES ARISING OUT
            OF OR RELATED TO THE MATTRESS OR ITS USE BY YOU OR ANY THIRD PARTY,
            WHETHER UNDER THEORY OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
            INDEMNITY, PRODUCT LIABILITY OR OTHERWISE. THIS LIMITATION WILL
            APPLY EVEN IF NOD HAS BEEN ADVISED OF SUCH DAMAGES AND
            NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
            REMEDY. NOD TOTAL LIABILITY WILL NOT EXCEED THE PURCHASE PRICE PAID
            FOR THE MATTRESS GIVING RISE TO SUCH LIABILITY.
          </p>
          <p className={Styles.termContent}>
            SOME PROVINCES OR TERRITORIES DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
            LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>
          <p className={Styles.termContent}>
            The product, opened or unopened, can only be returned within the
            101-Night Trial. In the event of a return, the pickup address can
            differ from the original delivery address. However, it must be at a
            valid street address within Canada. Any environmental and
            customer-inflicted damage will disqualify the ability to exchange or
            return.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WarrantyPage;
