import React from "react";
import Styles from "./Selector.module.css";

const Selector = props => {
  const { selector, selected } = Styles;
  return (
    <div
      onClick={() => props.clicked(props.name)}
      className={`${selector} ${props.selected ? selected : null}`}
    >
      <span>{props.name}</span>
    </div>
  );
};

export default Selector;
