import React from "react";
import logo from "../../assets/nodLogo.svg";
import Styles from "./ShopBar.module.css";
import VariantSelector from "../Shopify/VariantSelector/VariantSelector";
import posed from "react-pose";
import { Link } from "react-router-dom";

const PoseFaderDiv = posed.div({
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 }
});

class ShopBar extends React.Component {
  shopBarRef = React.createRef();

  constructor(props) {
    super(props);

    if (!this.props.product) {
      return null;
    }

    let defaultOptionValues = { Size: "twin" };

    const selectedVariant = this.props.client.product.helpers.variantForOptions(
      this.props.product,
      defaultOptionValues
    );

    this.state = {
      selectedOptions: defaultOptionValues,
      selectedVariant,
      isVisible: false
    };
  }

  scrollHandler(event) {
    const { isVisible } = this.state;
    const scrollPos = window.pageYOffset;
    const isTriggered = scrollPos >= 100;
    if (isVisible === isTriggered) {
      return;
    }
    this.setState({ isVisible: isTriggered });
  }

  componentDidMount() {
    document.addEventListener("scroll", e => this.scrollHandler(e));
  }

  handleOptionChange(event) {
    const target = event.target;
    const { selectedOptions } = this.state;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(
      this.props.product,
      selectedOptions
    );

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  render() {
    const { product } = this.props;
    const { selectedVariant } = this.state;
    const variantQuantity = 1;

    const { isVisible } = this.state;

    const variantSelectors = product && (
      <VariantSelector
        handleOptionChange={e => this.handleOptionChange(e)}
        key={product.id.toString()}
        product={product}
      />
    );

    return (
      <PoseFaderDiv
        pose={isVisible ? "visible" : "hidden"}
        className={Styles.container}
      >
        <Link className={Styles.logo} to="/#top" alt="nav logo">
          <img className={Styles.logo} src={logo} alt="nod logo" />
        </Link>
        <div className={Styles.controlsContainer}>
          <span>Live the dream</span>
          {variantSelectors}
          <button
            className={Styles.buy}
            onClick={() =>
              this.props.addVariantToCart(selectedVariant.id, variantQuantity)
            }
          >
            Add to Cart
          </button>
        </div>
      </PoseFaderDiv>
    );
  }
}

export default ShopBar;
