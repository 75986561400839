import React from "react";
import Faq from "../../Components/Faqs/Faq/Faq";
import Styles from "./FaqsPage.module.css";
import Spacer from "../../Components/Shared/Spacer/Spacer";
import FaqData from "./FaqData";

const FaqsPage = props => {
  return (
    <React.Fragment>
      <Spacer
        fontColor="#fff"
        color="var(--brand-mid-grey)"
        text="FAQ"
        size="2em"
        height="50px"
      />
      <div className={`${Styles.container} ${Styles.background}`}>
        <div className={Styles.faqsHeaderConatiner}>
          <h1 className={Styles.faqsHeader}>Frequently Asked Questions</h1>
        </div>
        <div className={Styles.faqs}>
          {FaqData.map(faq => (
            <Faq key={faq.q} {...faq} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FaqsPage;
