import React from "react";
import Spacer from "../../Components/Shared/Spacer/Spacer";
import Hows from "../../Components/How/Hows";
import Diagram from "../../Components/Diagram/Diagram";
import Banner from "../../Components/Banner/Banner";
import "./AboutPage.css";

const AboutPage = props => {
  return (
    <React.Fragment>
      <Spacer
        fontColor="#fff"
        color="var(--brand-mid-grey)"
        text="About Us"
        size="2em"
        height="50px"
      />
      <div className="about__container">
        <Diagram />
        <Banner />
        <div className="guyImage" />
        <Hows />
      </div>
    </React.Fragment>
  );
};

export default AboutPage;
